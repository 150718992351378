<template>
  <div class="instructions">
    <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#00000000"
      text-color="#000"
      active-text-color="#FFC322"
      router
    >
      <el-menu-item index="/help-center/picture-teaching/ue">UE</el-menu-item>
      <el-menu-item index="/help-center/picture-teaching/unity">Unity</el-menu-item>
    </el-menu>
    <div class="instructionsBox">
       <router-view />
    </div>
  </div>
</template>
<script>
export default {
  name: "instructions",
  components: {},
  data() {
    return {
      phone: "",
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.instructions {
   padding: 40px 110px 0 60px;
  .el-menu.el-menu--horizontal {
    display: inline-block;
    min-width: 100%;
    height: 100%;
    padding: 5px 0;
    border-bottom: none;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom: 1px solid #94EBE6;
    background-color: transparent !important;
    .el-menu-item {
      font-size: 18px;
      margin-left: 10px;
      background-color: transparent !important;
    }
  }
}
</style>
